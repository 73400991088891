@font-face {
  font-family: Digital Numbers;
  src: url("Digital-Numbers.aca489d2.ttf");
}

html {
  font-family: MD IO;
  font-style: normal;
  font-weight: 400;
}

body {
  color: #fff;
  background: #000;
  max-width: 1200px;
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #0000001a;
  border-radius: 4px;
}

img {
  display: block;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  overflow-y: scroll;
}

.content-retract {
  padding: 0 32px;
}

.header {
  box-sizing: border-box;
  background: #000;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  display: flex;
}

.header .left .logo {
  vertical-align: bottom;
  height: 40px;
  display: inline-block;
}

.header .right {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.header .right .faqs {
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  margin-left: 48px;
  margin-right: 14px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

.header .right .link-con {
  align-items: center;
  margin-left: 60px;
  display: flex;
}

.header .right .link-con .item {
  margin-left: 20px;
}

.header .right .link-con .item svg {
  height: 13px;
}

.line {
  opacity: .1;
  background: #fff;
  width: 100%;
  height: 1px;
}

@keyframes img-ani {
  from {
    top: 0;
  }

  to {
    top: -900px;
  }
}

@keyframes img-ani-r {
  from {
    top: -900px;
  }

  to {
    top: 0;
  }
}

@keyframes mask-ani {
  0% {
    opacity: .75;
  }

  75% {
    opacity: .75;
  }

  100% {
    opacity: 0;
  }
}

.hide {
  display: none;
}

.top-con {
  box-sizing: border-box;
  width: 1136px;
  height: 709px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.top-con img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0%;
}

.top-con img.ani {
  animation: 8s linear forwards img-ani;
}

.top-con img.ani-to-b {
  animation: 3.2s linear forwards img-ani;
}

.top-con img.ani-to-t {
  animation: 3.2s linear forwards img-ani-r;
}

.top-con .r-con {
  z-index: 2;
  position: absolute;
  bottom: 20px;
  right: 90px;
}

.top-con .time-con {
  z-index: 1;
  box-sizing: border-box;
  background: #000;
  width: 431px;
  height: 385px;
  padding-top: 64px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.top-con .time-con .title {
  letter-spacing: -.02em;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
}

.top-con .time-con .label {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

.top-con .time-con .titme-label {
  margin-top: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.top-con .time-con .time-items {
  justify-content: space-between;
  align-items: flex-start;
  width: 360px;
  margin-top: 16px;
  display: flex;
}

.top-con .time-con .time-items .time-item {
  text-align: center;
}

.top-con .time-con .time-items .time-item .time {
  font-family: Digital Numbers;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.top-con .time-con .time-items .time-item .time-unit {
  margin-top: 4px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.top-con .time-con .btn {
  text-transform: uppercase;
  cursor: pointer;
  color: #fff;
  background: #797bff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 360px;
  height: 48px;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 144%;
  text-decoration: none;
  display: flex;
  box-shadow: 0 6px 2px -4px #0e0e2c1a, inset 0 -1px #0e0e2c66;
}

.tips-con {
  width: 688px;
  padding-top: 56px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.intro-con {
  margin-top: 136px;
  margin-bottom: 96px;
}

.intro-con h1 {
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.intro-con .title {
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
}

.intro-con .intro-info {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.intro-con .intro-info .labels {
  width: 672px;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

.intro-con .intro-info .labels div {
  margin-bottom: 16px;
}

.intro-con .intro-info .labels div span {
  color: #4b4ded;
}

.intro-con .intro-info .right-con {
  text-align: center;
}

.intro-con .intro-info img {
  width: 416px;
  height: 482.56px;
}

.intro-con .intro-info .tips {
  color: #8c8ca2;
  margin-top: 17px;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 144%;
}

.rally-con {
  margin-top: 80px;
}

.rally-con h1 {
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.rally-con .tips {
  margin-top: 32px;
}

.rally-con .tips div {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

.rally-con .imgs-con {
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 48px;
  display: flex;
}

.rally-con .imgs-con .item {
  flex-direction: column;
  align-items: center;
  width: 260px;
  display: flex;
}

.rally-con .imgs-con .item img {
  width: 160px;
  height: 160px;
}

.rally-con .imgs-con .item .title {
  text-align: center;
  margin: 16px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
}

.rally-con .imgs-con .item .label {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.pre-con {
  margin-top: 120px;
  margin-bottom: 88px;
}

.pre-con h2 {
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
}

.pre-con .labels div {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.pre-con .imgs-con {
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
  display: flex;
}

.pre-con .imgs-con .item {
  text-align: left;
  width: 360px;
}

.pre-con .imgs-con .item img {
  cursor: pointer;
  width: 360px;
  height: 225px;
}

.pre-con .imgs-con .item .title {
  margin: 8px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
}

.pre-con .imgs-con .item .label {
  font-size: 14px;
  font-weight: 400;
  line-height: 144%;
}

.iceberg-con {
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 80px;
  display: flex;
}

.iceberg-con .left-con img {
  width: 438px;
}

.iceberg-con .right-con {
  width: 576px;
}

.iceberg-con .right-con h1 {
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 56px;
  font-weight: 700;
  line-height: 120%;
}

.iceberg-con .right-con .tips div {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 150%;
}

.iceberg-con .right-con .tips div svg {
  width: 15px;
  height: 15px;
}

.iceberg-con .right-con .merge-con a {
  -webkit-text-decoration: unset;
  text-decoration: unset;
  color: #4b4ded;
}

.story-con {
  margin-top: 46px;
  display: flex;
}

.story-con .left-con {
  box-sizing: border-box;
  background: #4b4ded;
  width: 50%;
  padding: 64px 32px 0;
  display: inline-block;
}

.story-con .left-con img {
  float: right;
  width: 100px;
  margin-right: 8px;
  display: block;
}

.story-con .left-con h1 {
  margin-top: 100px;
  margin-bottom: 32px;
  font-size: 56px;
  font-weight: 700;
  line-height: 120%;
}

.story-con .left-con .labels div {
  margin-bottom: 16px;
  font-size: 16px;
  font-style: italic;
  line-height: 150%;
}

.story-con .right-con {
  width: 50%;
  display: inline-block;
}

.story-con .right-con img {
  width: 100%;
  display: block;
}

.faqs-con {
  margin-bottom: 72px;
}

.faqs-con h1 {
  margin-top: 80px;
  margin-bottom: 32px;
  font-size: 56px;
  font-weight: 700;
  line-height: 120%;
}

.faqs-con .tips {
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

.faqs-con .faqs-items {
  background: #797bff1a;
}

.faqs-con .faqs-items .item {
  cursor: pointer;
  border-bottom: 1px solid #ffffff1a;
  padding: 27px 32px;
}

.faqs-con .faqs-items .item .title-con {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.faqs-con .faqs-items .item .item-con {
  opacity: 0;
  height: 0;
}

.faqs-con .faqs-items .item.open .title-con {
  color: #797bff;
}

.faqs-con .faqs-items .item.open .item-con {
  opacity: 1;
  height: auto;
  padding-top: 20px;
  transition: height .4s ease-in, opacity .4s ease-in;
}

.faqs-con .faqs-items .item.open .arrow {
  transform: rotate(-180deg);
}

.entry .footer-con {
  margin-bottom: 80px;
}

.footer-con {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1280px;
  height: 177px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.footer-con:before {
  content: " ";
  background: #fff;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: -40px;
}

.footer-con .link-con {
  align-items: center;
  display: flex;
}

.footer-con .link-con .item {
  margin: 0 10px;
}

.footer-con .link-con .item svg {
  height: 28px;
}

.footer-con .label {
  margin-top: 25px;
  font-size: 10px;
  line-height: 144%;
}

.fixed-con {
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  z-index: 1000;
  background: #797bff1a;
  justify-content: space-between;
  align-items: center;
  width: 1136px;
  height: 106px;
  padding: 0 32px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.fixed-con .title {
  width: 320px;
  font-size: 24px;
  font-style: italic;
  line-height: 120%;
}

.fixed-con .label {
  width: 400px;
  margin: 0 8px;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%;
}

.fixed-con .fixed-btn {
  cursor: pointer;
  color: #fff;
  background: #797bff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 288px;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  line-height: 144%;
  text-decoration: none;
  display: flex;
  box-shadow: 0 6px 2px -4px #0e0e2c1a, inset 0 -1px #0e0e2c66;
}

.fixed-con .close {
  cursor: pointer;
  margin-left: 10px;
}

.home .top-con .time-con {
  z-index: 10;
  flex-direction: column;
  justify-content: space-between;
  height: 260px;
  padding: 64px 24px 24px 0;
  display: flex;
}

.home .top-con .time-con .btn {
  width: 285px;
}

.home .top-con .mask {
  opacity: 0;
  z-index: 2;
  background: #000;
  width: 100vw;
  height: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

.home .top-con .mask.mask-ani {
  animation: 4.2s linear forwards mask-ani;
}

.home .footer-con {
  margin-top: 56px;
}

.dialog {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  z-index: 100000;
  background: #0d0d1ecc;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.dialog img {
  width: 1080px;
  height: 675px;
  margin-bottom: 30px;
}

.sm-show {
  display: none !important;
}

.nav-panel {
  display: none;
}

@media screen and (width <= 768px) {
  @keyframes img-ani {
    from {
      top: 0;
    }

    to {
      top: -41.37%;
    }
  }

  @keyframes img-ani-r {
    from {
      top: -41.37%;
    }

    to {
      top: 0;
    }
  }

  .sm-hide {
    display: none !important;
  }

  .sm-show {
    display: block !important;
  }

  .content, body, html {
    max-width: unset;
    box-sizing: border-box;
    width: 100vw;
  }

  .content-retract {
    padding: 0 16px;
  }

  .header {
    padding: 0 24px;
  }

  .nav-icon {
    padding-right: 20px;
  }

  .nav-panel {
    z-index: 10000;
    background: #000;
    width: 100%;
    display: none;
    position: absolute;
    top: 88px;
    left: 0;
  }

  .nav-panel.nav-show {
    display: block;
  }

  .nav-panel .faqs {
    justify-content: flex-end;
    align-items: center;
    padding: 20px 40px;
    font-size: 14px;
    font-weight: 600;
    line-height: 144%;
    display: flex;
  }

  .nav-panel .link-con {
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 40px;
    display: flex;
  }

  .nav-panel .link-con a {
    width: 24px;
    height: 24px;
    margin-left: 24px;
  }

  .home .top-con, .entry .top-con {
    height: unset;
    width: 100vw;
  }

  .home .top-con .top-img-con, .entry .top-con .top-img-con {
    height: 100vw;
    overflow: hidden;
  }

  .home .top-con #bg-img, .entry .top-con #bg-img {
    position: relative;
  }

  .home .top-con #bg-img.ani, .entry .top-con #bg-img.ani {
    animation: 3s linear forwards img-ani;
  }

  .home .top-con #bg-img.ani-to-b, .entry .top-con #bg-img.ani-to-b {
    animation: 3.2s linear forwards img-ani;
  }

  .home .top-con #bg-img.ani-to-t, .entry .top-con #bg-img.ani-to-t {
    animation: 3.2s linear forwards img-ani-r;
  }

  .home .top-con .r-con, .entry .top-con .r-con {
    top: calc(100vw - 50px);
    right: 20px;
  }

  .home .top-con .time-con, .entry .top-con .time-con {
    box-sizing: border-box;
    height: unset;
    width: 100vw;
    margin-top: 40px;
    padding: 0 16px 32px;
    position: relative;
    bottom: 0;
  }

  .home .top-con .time-con .time-items, .entry .top-con .time-con .time-items {
    box-sizing: border-box;
    width: 100%;
    padding: 0 24px;
  }

  .home .top-con .time-con .label, .entry .top-con .time-con .label {
    width: 70%;
    margin-top: 16px;
  }

  .home .top-con .time-con .btn, .entry .top-con .time-con .btn {
    width: 100%;
  }

  .home .top-con .time-con .time-line-con, .entry .top-con .time-con .time-line-con {
    box-sizing: border-box;
    width: 100%;
    padding-top: 24px;
  }

  .home .top-con .time-con .time-line-con .line, .entry .top-con .time-con .time-line-con .line {
    opacity: 1;
    background: #fff;
  }

  .home .top-con .time-con .title {
    margin-bottom: 24px;
  }

  .tips-con {
    box-sizing: border-box;
    width: 100vw;
  }

  .intro-con {
    margin-top: 80px;
    padding: 0;
  }

  .intro-con h1 {
    margin-bottom: 32px;
    padding: 0 16px;
    font-size: 32px;
  }

  .intro-con .title {
    padding: 0 16px;
    font-size: 24px;
  }

  .intro-con .intro-info {
    flex-wrap: wrap;
  }

  .intro-con .intro-info .labels {
    width: 100%;
    padding: 0 16px;
  }

  .intro-con .intro-info .right-con {
    background: #1c1d20;
  }

  .intro-con .intro-info .right-con img {
    width: 100%;
    height: auto;
  }

  .intro-con .intro-info .right-con .tips {
    margin-bottom: 24px;
  }

  .rally-con {
    margin-top: -32px;
  }

  .rally-con h1 {
    font-size: 32px;
  }

  .rally-con .imgs-con {
    flex-wrap: wrap;
    margin-top: 8px;
  }

  .rally-con .imgs-con .item {
    width: 100%;
    margin-top: 40px;
  }

  .pre-con {
    margin-top: 56px;
  }

  .pre-con h2 {
    font-size: 24px;
  }

  .pre-con .imgs-con {
    flex-wrap: wrap;
  }

  .pre-con .imgs-con .item {
    width: 100%;
    margin-top: 40px;
  }

  .iceberg-con {
    flex-flow: column-reverse wrap;
  }

  .iceberg-con .left-con img {
    height: unset;
    width: 100%;
  }

  .iceberg-con .right-con {
    width: 100%;
  }

  .iceberg-con .right-con h1 {
    font-size: 32px;
  }

  .story-con {
    flex-wrap: wrap;
  }

  .story-con .left-con, .story-con .right-con {
    width: 100%;
    display: block;
  }

  .story-con .left-con {
    background: #797bff;
  }

  .story-con .left-con h1 {
    font-size: 32px;
  }

  .start-con {
    box-sizing: border-box;
    background: #797bff;
    width: 100%;
    padding: 40px 16px;
  }

  .start-con .title {
    font-size: 24px;
    font-style: italic;
    line-height: 120%;
  }

  .start-con .label {
    margin-top: 24px;
    font-size: 14px;
    font-style: italic;
    line-height: 150%;
  }

  .start-con .start-btn {
    color: #797bff;
    background: #fff;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 144%;
    text-decoration: none;
    display: flex;
    box-shadow: 0 6px 2px -4px #0e0e2c1a, inset 0 -1px #0e0e2c66;
  }

  .faqs-con {
    margin-bottom: 32px;
  }

  .faqs-con h1 {
    font-size: 32px;
  }

  .footer-con {
    width: 100%;
  }

  .footer-con:before {
    left: 0;
  }

  .dialog img {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }
}

/*# sourceMappingURL=nft.981e74d9.css.map */
