@font-face {
    font-family: 'Digital Numbers';
    src: url('../../common/font/Digital-Numbers.ttf');
}
html {
    font-family: 'MD IO';
    font-weight: 400;
    font-style: normal;
}
body {
    margin: 0;
    background: #000000;
    color: #fff;
    max-width: 1200px;
    margin: 0 auto;
}
/* 设置滚动条的样式 */
*::-webkit-scrollbar {
    width: 6px;
}
*::-webkit-scrollbar-track {
    border-radius: 4px;
}
*::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
}
img {
    display: block;
}
.content {
    max-width: 1200px;
    margin: 0 auto;
    overflow-y: scroll;
}
.content-retract {
    padding: 0 32px;
}
.header {
    height: 88px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    .left {
        .logo {
            height: 40px;
            display: inline-block;
            vertical-align: bottom;
        }
    }
    .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .faqs {
            margin-right: 14px;
            font-weight: 600;
            font-size: 14px;
            margin-left: 48px;
            text-decoration: none;
            text-transform: uppercase;
            color: #fff;
            cursor: pointer;
        }
        .link-con {
            margin-left: 60px;
            display: flex;
            align-items: center;
            .item {
                margin-left: 20px;
                svg {
                    height: 13px;
                }
            }
        }
    }
}
.line {
    width: 100%;
    background: #fff;
    height: 1px;
    opacity: 0.1;
}
@keyframes img-ani {
    from {
        top: 0px;
    }
    to {
        top: -900px;
    }
}
@keyframes img-ani-r {
    from {
        top: -900px;
    }
    to {
        top: 0;
    }
}
@keyframes mask-ani {
    0% {
        opacity: 0.75;
    }
    75% {
        opacity: 0.75;
    }
    100% {
        opacity: 0;
    }
}
.hide {
    display: none;
}
.top-con {
    width: 1136px;
    height: 709px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto;
    img {
        width: 100%;
        position: absolute;
        left: 0%;
        top: 0;
        &.ani {
            animation: img-ani 8s forwards linear;
        }
        &.ani-to-b {
            animation: img-ani 3.2s forwards linear;
        }
        &.ani-to-t {
            animation: img-ani-r 3.2s forwards linear;
        }
    }
    .r-con {
        position: absolute;
        right: 90px;
        bottom: 20px;
        z-index: 2;
        // cursor: pointer;
    }
    .time-con {
        position: absolute;
        width: 431px;
        height: 385px;
        background: #000;
        bottom: 0;
        left: 0;
        padding-top: 64px;
        z-index: 1;
        box-sizing: border-box;
        .title {
            font-weight: 700;
            font-size: 32px;
            line-height: 120%;
            letter-spacing: -0.02em;
        }
        .label {
            margin-top: 32px;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
        }
        .titme-label {
            margin-top: 40px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
        }
        .time-items {
            display: flex;
            width: 360px;
            align-items: flex-start;
            justify-content: space-between;
            margin-top: 16px;
            .time-item {
                text-align: center;
                .time {
                    font-family: 'Digital Numbers';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 32px;
                }
                .time-unit {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    margin-top: 4px;
                }
            }
        }
        .btn {
            width: 360px;
            height: 48px;
            background: #797bff;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            cursor: pointer;
            color: #fff;
            text-decoration: none;
            margin-top: 24px;
            box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.4);
            border-radius: 8px;
            font-weight: 600;
            font-size: 16px;
            line-height: 144%;
        }
    }
}
.tips-con {
    padding-top: 56px;
    width: 688px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
}
.intro-con {
    margin-top: 136px;
    margin-bottom: 96px;
    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 120%;
    }
    .title {
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
        margin-bottom: 32px;
    }
    .intro-info {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .labels {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            width: 672px;
            div {
                margin-bottom: 16px;
                span {
                    color: #4b4ded;
                }
            }
        }
        .right-con {
            text-align: center;
        }
        img {
            width: 416px;
            height: 482.56px;
        }
        .tips {
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            line-height: 144%;
            color: #8c8ca2;
            margin-top: 17px;
        }
    }
}
.rally-con {
    margin-top: 80px;
    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 120%;
    }
    .tips {
        margin-top: 32px;
        div {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 16px;
        }
    }
    .imgs-con {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 48px;
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 260px;
            img {
                width: 160px;
                height: 160px;
            }
            .title {
                margin: 16px 0;
                font-weight: 600;
                font-size: 24px;
                line-height: 120%;
                text-align: center;
            }
            .label {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                text-align: center;
            }
        }
    }
}
.pre-con {
    margin-top: 120px;
    margin-bottom: 88px;
    h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
        margin-bottom: 32px;
    }
    .labels {
        div {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 16px;
        }
    }
    .imgs-con {
        margin-top: 40px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .item {
            width: 360px;
            text-align: left;
            img {
                width: 360px;
                height: 225px;
                cursor: pointer;
            }
            .title {
                font-weight: 600;
                font-size: 24px;
                line-height: 120%;
                margin: 8px 0;
            }
            .label {
                font-weight: 400;
                font-size: 14px;
                line-height: 144%;
            }
        }
    }
}
.iceberg-con {
    margin-top: 80px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .left-con {
        img {
            width: 438px;
        }
    }
    .right-con {
        width: 576px;
        h1 {
            font-weight: 700;
            font-size: 56px;
            line-height: 120%;
            margin-bottom: 32px;
            margin-top: 0;
        }
        .tips {
            div {
                svg {
                    width: 15px;
                    height: 15px;
                }
                font-size: 16px;
                line-height: 150%;
                margin-bottom: 16px;
            }
        }
        .merge-con {
            a {
                text-decoration: unset;
                color: #4b4ded;
            }
        }
    }
}
.story-con {
    display: flex;
    margin-top: 46px;
    .left-con {
        padding: 64px 32px 0 32px;
        display: inline-block;
        width: 50%;
        background: #4b4ded;
        box-sizing: border-box;
        img {
            width: 100px;
            float: right;
            margin-right: 8px;
            display: block;
        }
        h1 {
            margin-top: 100px;
            font-weight: 700;
            font-size: 56px;
            line-height: 120%;
            margin-bottom: 32px;
        }
        .labels {
            div {
                margin-bottom: 16px;
                font-size: 16px;
                line-height: 150%;
                font-style: italic;
            }
        }
    }
    .right-con {
        display: inline-block;
        width: 50%;
        img {
            width: 100%;
            display: block;
        }
    }
}
.faqs-con {
    margin-bottom: 72px;
    h1 {
        font-weight: 700;
        font-size: 56px;
        line-height: 120%;
        margin-bottom: 32px;
        margin-top: 80px;
    }
    .tips {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 40px;
    }
    .faqs-items {
        background: rgba(121, 123, 255, 0.1);
        .item {
            padding: 27px 32px;
            cursor: pointer;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            .title-con {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .item-con {
                height: 0;
                opacity: 0;
            }
            &.open {
                .title-con {
                    color: #797bff;
                }
                .item-con {
                    padding-top: 20px;
                    opacity: 1;
                    height: auto;
                    transition: height 0.4s ease-in, opacity 0.4s ease-in;
                }
                .arrow {
                    transform: rotate(-180deg);
                }
            }
        }
    }
}
.entry {
    .footer-con {
        margin-bottom: 80px;
    }
}
.footer-con {
    width: 1280px;
    margin: 0 auto;
    height: 177px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    &::before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 1px;
        background: #fff;
        top: 0;
        left: -40px;
    }
    .link-con {
        display: flex;
        align-items: center;
        .item {
            margin: 0 10px;
            svg {
                height: 28px;
            }
        }
    }
    .label {
        font-size: 10px;
        line-height: 144%;
        margin-top: 25px;
    }
}
.fixed-con {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 1136px;
    padding: 0 32px;
    box-sizing: border-box;
    background: #000;
    height: 106px;
    background: rgba(121, 123, 255, 0.1);
    backdrop-filter: blur(24px);

    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    left: 50%;
    transform: translateX(-50%);
    .title {
        font-style: italic;
        font-size: 24px;
        line-height: 120%;
        width: 320px;
    }
    .label {
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        width: 400px;
        margin: 0 8px;
    }
    .fixed-btn {
        background: #797bff;
        box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.4);
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 144%;
        width: 288px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
        color: #fff;
    }
    .close {
        margin-left: 10px;
        cursor: pointer;
    }
}
.home {
    .top-con {
        .time-con {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 260px;
            padding: 64px 24px 24px 0;
            z-index: 10;
            .btn {
                width: 285px;
            }
        }
        .mask {
            background: #000;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vw;
            z-index: 2;
            &.mask-ani {
                animation: mask-ani 4.2s forwards linear;
            }
        }
    }
    .footer-con {
        margin-top: 56px;
    }
}
.dialog {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(13, 13, 30, 0.8);
    backdrop-filter: blur(8px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
    z-index: 100000;
    img {
        width: 1080px;
        height: 675px;
        margin-bottom: 30px;
    }
}
.sm-show {
    display: none !important;
}
.nav-panel {
    display: none;
}
// .sm-hide {
//     display: block;
// }

// 移动端
@media screen and (max-width: 768px) {
    @keyframes img-ani {
        from {
            top: 0px;
        }
        to {
            top: -41.37%;
        }
    }
    @keyframes img-ani-r {
        from {
            top: -41.37%;
        }
        to {
            top: 0;
        }
    }
    .sm-hide {
        display: none !important;
    }
    .sm-show {
        display: block !important;
    }
    .content,
    body,
    html {
        width: 100vw;
        max-width: unset;
        box-sizing: border-box;
    }
    .content-retract {
        padding: 0 16px;
    }
    .header {
        padding: 0 24px;
    }
    .nav-icon {
        padding-right: 20px;
    }
    .nav-panel {
        display: none;
        position: absolute;
        top: 88px;
        left: 0;
        width: 100%;
        background: #000;
        z-index: 10000;
        &.nav-show {
            display: block;
        }
        .faqs {
            padding: 20px 40px;
            font-weight: 600;
            font-size: 14px;
            line-height: 144%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        .link-con {
            padding-right: 40px;
            padding-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            a {
                margin-left: 24px;
                width: 24px;
                height: 24px;
            }
        }
    }
    .home,
    .entry {
        .top-con {
            width: 100vw;
            height: unset;
            .top-img-con {
                height: 100vw;
                overflow: hidden;
            }
            #bg-img {
                position: relative;
                &.ani {
                    animation: img-ani 3s forwards linear;
                }
                &.ani-to-b {
                    animation: img-ani 3.2s forwards linear;
                }
                &.ani-to-t {
                    animation: img-ani-r 3.2s forwards linear;
                }
            }
            .r-con {
                top: calc(100vw - 50px);
                right: 20px;
            }
            .time-con {
                position: relative;
                width: 100vw;
                padding: 0 16px 32px 16px;
                bottom: 0;
                box-sizing: border-box;
                height: unset;
                margin-top: 40px;
                .time-items {
                    width: 100%;
                    padding: 0 24px;
                    box-sizing: border-box;
                }
                .label {
                    margin-top: 16px;
                    width: 70%;
                }
                .btn {
                    width: 100%;
                }
                .time-line-con {
                    width: 100%;
                    box-sizing: border-box;
                    padding-top: 24px;
                    .line {
                        background: #fff;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .home {
        .top-con .time-con .title {
            margin-bottom: 24px;
        }
    }
    .tips-con {
        width: 100vw;
        box-sizing: border-box;
    }
    .intro-con {
        margin-top: 80px;
        padding: 0;
        h1 {
            margin-bottom: 32px;
            padding: 0 16px;
            font-size: 32px;
        }
        .title {
            padding: 0 16px;
            font-size: 24px;
        }
        .intro-info {
            flex-wrap: wrap;
            .labels {
                width: 100%;
                padding: 0 16px;
            }
            .right-con {
                background: #1c1d20;
                img {
                    width: 100%;
                    height: auto;
                }
                .tips {
                    margin-bottom: 24px;
                }
            }
        }
    }
    .rally-con {
        margin-top: -32px;
        h1 {
            font-size: 32px;
        }
        .imgs-con {
            flex-wrap: wrap;
            margin-top: 8px;
            .item {
                width: 100%;
                margin-top: 40px;
            }
        }
    }
    .pre-con {
        margin-top: 56px;
        h2 {
            font-size: 24px;
        }
        .imgs-con {
            flex-wrap: wrap;
            .item {
                width: 100%;
                margin-top: 40px;
            }
        }
    }
    .iceberg-con {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        .left-con {
            img {
                width: 100%;
                height: unset;
            }
        }
        .right-con {
            width: 100%;
            h1 {
                font-size: 32px;
            }
        }
    }
    .story-con {
        flex-wrap: wrap;
        .left-con,
        .right-con {
            width: 100%;
            display: block;
        }
        .left-con {
            background: #797bff;
            h1 {
                font-size: 32px;
            }
        }
    }
    .start-con {
        width: 100%;
        box-sizing: border-box;
        padding: 40px 16px;
        background: #797bff;
        .title {
            font-style: italic;
            font-size: 24px;
            line-height: 120%;
        }
        .label {
            font-style: italic;
            font-size: 14px;
            line-height: 150%;
            margin-top: 24px;
        }
        .start-btn {
            display: block;
            width: 100%;
            height: 48px;
            background: #ffffff;
            box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.4);
            border-radius: 8px;
            color: #797bff;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 144%;
            margin-top: 24px;
        }
    }
    .faqs-con {
        margin-bottom: 32px;
        h1 {
            font-size: 32px;
        }
    }
    .footer-con {
        width: 100%;
        &::before {
            left: 0;
        }
    }
    .dialog {
        img {
            width: 100%;
            height: auto;
            margin-bottom: 30px;
        }
    }
}
